/* CrawledCoupons.css */
.row-new {
    background-color: #d4edda; /* Verde claro */
    color: #155724; /* Texto verde escuro */
  }

.row-removed {
  background-color: #f8d7da; /* Vermelho claro */
  color: #721c24; /* Texto vermelho escuro */
}
.row-other {
  background-color: #dbeafe; /* Azul claro */
  color: #1e3a8a; /* Texto azul escuro */
}
